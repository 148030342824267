<!--
 * @Author: 张博洋
 * @Date: 2021-07-22 15:01:04
 * @LastEditTime: 2021-12-13 16:06:10
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/address/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="list">
    <div class="top">
      <div v-if="list.length">
        <van-swipe-cell class="item"
                        v-for="(item,index) in list"
                        :key="index">
          <div class="mt13 mb13 ml16 mr16"
               @click="check">
            <p style="display: flex; flex-wrap: wrap; align-items: center;">
              <span class="name mr12">{{item.userName}}</span>
              <span class="phone">{{item.userPhone}}</span>
            </p>
            <p class="adress mt8">{{item.provinceName + item.cityName + item.countyName}}</p>
            <p class="adress mt2">{{item.address}}</p>
          </div>

          <template #right>
            <van-button square
                        text="编辑"
                        color="rgba(26, 179, 112, 1)"
                        class="delete-button"
                        @click="edit(item.id)" />
          </template>
        </van-swipe-cell>

        <!-- <p class="tip">温馨提示 <br>（1）每个账号最多可以绑定10个就诊人，暂不允许解绑，谨慎操作。 <br>（2）请确保所填全部信息真实准确，否则将导致业务无法办理，因此带来的损失由本人自行承担。</p> -->
      </div>

      <div class="empty"
           v-else>
        <img width="120"
             height="120"
             src="@/static/img/adress.svg"
             alt="">
        <p>暂无地址</p>
      </div>

    </div>

    <div class="btn-group mt8">
      <van-button class="add"
                  icon="plus"
                  round
                  @click="create">新建收货地址</van-button>

    </div>
  </div>
</template>

<script>
import SwipeCell from '@/mixin/SwipeCell.js'

export default {
  mixins: [SwipeCell],
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getAddressList()
  },
  activated() {
    this.getAddressList()
  },
  methods: {
    check() {},
    getAddressList() {
      this.$axios({
        type: 'post',
        url: 'delivery/queryList',
        elseData: {
          loading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.list = [...res.d.data]
      })
    },
    edit(id) {
      this.$router.push({
        name: 'address.edit',
        query: {
          id: id,
        },
      })
    },
    create() {
      this.$router.push({
        name: 'address.edit',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    overflow-y: auto;
    .name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .phone {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .adress {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.item {
  margin: 8px 8px 0 8px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .edit {
    width: 40px;
    height: 100%;
    color: #fff;
    background: rgba(26, 179, 112, 1);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 17px;
    }
  }
  .van-swipe-cell__right {
    .van-button {
      height: 100%;
    }
  }
}

.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .add {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>